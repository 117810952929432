import { useState } from 'react';
import './App.css';
import svLargeIntro from './assets/IMG_2680.jpg';
import enLargeIntro from './assets/IMG_2685.jpg';
import svQA from './assets/IMG_2684.jpg';
import enQA from './assets/IMG_2683.jpg';
import svHomeMenu from './assets/svHome.png';
import svQAMenu from './assets/svQA.png';
import enQAMenu from './assets/enQA.png';
import enHomeMenu from './assets/enHome.png';

function App() {
  const [currentPage, setCurrentPage] = useState('hem');
  const [swedish, setSwedish] = useState<boolean>(true);
  return (
    <div style={{textAlign: 'center'}}>
      <div style={{
        backgroundColor: '#d5e5f0',
        display: 'flex', 
        position: 'fixed', 
        justifyContent: 'center', 
        width: '100%', 
        gap: '20px', // adds space between menu items
        left: 0, 
        cursor: 'pointer',
        top: 0,
        paddingTop: '8px',
      }}>
        <div 
          style={{ 
            fontWeight: 900,
            borderBottom: '5px solid',
            borderBottomColor: currentPage === 'hem' ? '#2874a5' : 'transparent',
            borderTopRightRadius: 4,
            borderTopLeftRadius: 8,
            transition: 'border-bottom-color 0.3s ease'
          }} 
          onClick={() => {
          const hem = document.getElementById('hem')
          hem && hem.scrollIntoView({ behavior: 'smooth' })
          setCurrentPage('hem')
        }}>
        <img style={{width: '100px'}} src={swedish ? svHomeMenu : enHomeMenu} alt='spårvagn'/>
        </div>
        <div
            style={{ 
              fontWeight: 900,
              borderBottom: '5px solid',
              borderBottomColor: currentPage === 'fragor' ? '#2874a5' : 'transparent',
              borderTopRightRadius: 4,
              borderTopLeftRadius: 8,
              transition: 'border-bottom-color 0.3s ease'
            }} 
            onClick={() => {
              const fragor = document.getElementById('fragor')
              fragor && fragor.scrollIntoView({ behavior: 'smooth' })
              setCurrentPage('fragor')
            }}>
          <img style={{width: swedish ? '160px' : '140px'}} src={swedish ? svQAMenu : enQAMenu} alt='spårvagn'/>
        </div>
        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center'}} onClick={() => {
          setSwedish(!swedish)
        }}>
          <span style={{textDecoration: 'underline', cursor: 'pointer'}}>
            {swedish ? 'English ' : 'Svenska '}
            <span style={{fontSize: '15px'}}>{swedish ? '🇬🇧' : '🇸🇪'}</span>
          </span>
        </div>
      </div>
      <div id="hem" style={{height: '65vh'}}>
          {/* <h1 style={{ paddingTop: '80px' }}>Spara datumet</h1> */}
          {/* <h3>Lina & Marcus gifter sig den 2:a augusti 2025 i Göteborg</h3> */}
          {/* <h4>Avvakta inbjudan</h4> */}
          <div style={{ paddingRight: '16px', paddingLeft: '16px', paddingTop: '96px', maxHeight: '40vh' }}>
            <img style={{
                maxWidth: '100%', 
                height: 'auto', 
                maxHeight: '55vh'}} src={swedish ? svLargeIntro : enLargeIntro} alt='spårvagn'/>
          </div>
      </div>
      <div id="fragor" style={{height: '100vh'}}>
          <div style={{
            width: 'fit-content',
            margin: '0 auto',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            paddingTop: '96px'
          }}>
            {/* <h1 style={{ paddingTop: '80px', textAlign: 'center' }}>Frågor och svar</h1> */}
            <img style={{
                maxWidth: '40%', 
                height: 'auto', 
                maxHeight: '40vh'}} src={swedish ? svQA : enQA} alt='qa'/>
            <div style={{ maxWidth: 600, padding: 16, marginLeft: 16, marginRight: 16, textAlign: 'left'}}>
              <h4>{swedish ? 'F: Var ska festen hållas?' : 'Q: Where is the venue for the party?'}</h4>
              <p>{swedish ? 'S: På Överås Slott, danska vägen 20 i Göteborg.' : 'A: At Överås Slott, danska vägen 20, in Gothenburg'}</p>
              <h4>{swedish ? 'F: Har ni tips på hotell?' : 'Q: Do you have any recommendations for accommodation?'}</h4>
              <p>{swedish ? 'S: Det finns flera hotell runt Korsvägen, till exempel Gothia Towers eller Hotell Onyxen. 5:ans Bed & Breakfast är närmast. Hör av er om ni vill ha fler tips!' : 'A: There are plenty of hotels near Korsvägen, for example Gothia Towers or Hotell Onyxen. 5:ans Bed & Breakfast is the one located the closest to the venue'}</p>
              <h4>{swedish ? 'F: Får barn vara med?' : 'Q: Can kids come along?'}</h4>
              <p>{swedish ? 'S: Vi tycker om era barn, men just den här dagen vill vi fira i vuxet sällskap. Bebisar och barn som ammar får självklart vara med.' : 'A: We like your kids, but this day we want to celebrate in adult company. Infants and kids that are being breast fed are of course allowed to come along.'}</p>
            </div>
          </div>
      </div>
    </div>
  );
}

export default App;


// F: Var ska festen hållas?
// S: På Överås Slott, danska vägen 20 i Göteborg.


// F: Har ni tips på hotell?
// S: Det finns flera hotell runt Korsvägen, till exempel Gothia Towers eller Hotell Onyxen. 5:ans Bed & Breakfast är närmast. Hör av er om ni vill ha fler tips!

// F: Får barn vara med?
// S: Vi tycker om era barn, men just den här dagen vill vi fira i vuxet sällskap. Bebisar och barn som ammar får självklart vara med.
